import LogoBeta from '@internal/ui/brand/svg/logo-primary.svg';
import { ButtonLink } from '@internal/ui/components/ButtonLink';
import { Breakpoint } from '@internal/ui/css/breakpoints';
import Link from 'next/link';
import { rem } from 'polished';
import React from 'react';
import { useAppSelector } from 'state/hooks';
import styled from 'styled-components';
import { hasStaffPermissions, StaffLevel } from '../constants/staff';
import AppNav from './AppNav';

interface NavLoggedIn {
    className?: string;
}

const NavLoggedIn: React.FunctionComponent<NavLoggedIn> = (props) => {
    const { className, children } = props;

    const userID = useAppSelector((state) => state.user.id);

    return (
        <AppNav className={className}>
            <Link href={userID ? '/home' : '/'}>
                <LogoContainerStyled href={userID ? '/home' : '/'}>
                    <LogoBeta />
                </LogoContainerStyled>
            </Link>
            <NavActionsContainerStyled>
                {hasStaffPermissions(userID, StaffLevel.Contractor) && (
                    <Link href="/mod">
                        <ButtonLink
                            href="/mod"
                            size="medium"
                            variant="negativeLoud"
                            text="Mod Dashboard"
                        />
                    </Link>
                )}
                {children}
            </NavActionsContainerStyled>
        </AppNav>
    );
};

const LogoContainerStyled = styled.a`
    height: ${rem(17)};
    width: ${rem(165)};

    svg {
        height: 100%;
        width: 100%;
    }

    @media screen and (min-width: ${rem(Breakpoint.Small)}) {
        height: ${rem(22)};
        width: ${rem(212)};
    }
`;

const NavActionsContainerStyled = styled.div`
    margin-left: auto;
    z-index: 1;
    display: flex;
    align-items: center;
    gap: ${rem(12)};
`;

export default NavLoggedIn;
